<template>
  <b-modal
    :visible="modal.show"
    body-class="app-modal"
    hide-header
    hide-header-close
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="header">
      <span class="title">{{ title }}</span>
      <i @click="hideAppModal"><icon-close /></i>
    </div>
    <div class="wallet-info" v-if="modal.type === 'deposit'">
      <p>
        Please make a deposit of USDT on the TRON network (TRC20) to the
        provided wallet address. Once we receive the transaction, we will update
        its status accordingly.<br /><br />
        If the transaction status is not updated within 24 hours, please contact
        us and provide the necessary details of your transaction.
      </p>
      <table class="wallet-table">
        <tr>
          <qrcode-vue :value="modal.wallet" :size="200" level="H" />
        </tr>
        <tr>
          <p ref="walletAddress" class="wallet">{{ modal.wallet }}</p>
          <button class="btn-copy" @click="copyText()">Copy</button>
        </tr>
      </table>
    </div>
    <div class="wallet-info" v-if="modal.type === 'withdraw'">
      <p>
        To proceed with the withdrawal, please provide your USDT wallet address
        on the TRON network (TRC20) and specify the amount you wish to withdraw.
        We will process your request and send the funds back to you as soon as
        possible.<br /><br />
        In the event that you do not receive the withdrawal within 24 hours,
        please reach out to us for further assistance.<br /><br />
        Kindly note that a withdrawal fee of 2% will be deducted, with a minimum
        fee of 5 USDT.
      </p>
    </div>
    <div class="content" v-if="modal.type !== 'deposit'">
      <b-form-group
        v-if="modal.type !== 'redeem'"
        label-for="amount"
        label="Amount"
      >
        <b-form-input
          id="amount"
          type="number"
          v-model.number="amount"
          placeholder="0"
          :min="0"
          :state="inputState"
          :formatter="amountFormatter"
        ></b-form-input>
        <span class="description" v-if="showMaxDescription">
          Max:
          <em @click="assignValue(modal.max)">{{
            numeral(modal.max).format("0,0.00", Math.floor)
          }}</em>
          USDT
        </span>
        <span class="description" v-if="modal.type === 'withdraw'">
          Fee:
          {{ numeral(getFee()).format("0,0.00", Math.floor) }}
          USDT
        </span>
        <span class="description" v-if="modal.type === 'withdraw'">
          Total:
          {{ numeral(getTotal()).format("0,0.00", Math.ceil) }}
          USDT
        </span>
      </b-form-group>
      <p v-else>
        You are planning to redeem your fixed interest. The redemption amount
        will be received within a maximum of 120 days, after which you will no
        longer be eligible to receive any further interest. Are you certain
        about your decision to proceed with this redemption?
      </p>
      <div class="interest-trial" v-if="modal.type === 'apply'">
        <ul>
          <li>
            <div class="phase">Daily Interest</div>
            <div class="interest">
              {{
                numeral(getDailyInterest(modal.dayrate)).format(
                  "0,0.000000",
                  Math.ceil
                )
              }}
              <em>USDT</em>
            </div>
          </li>
          <li>
            <div class="phase">Monthly Interest</div>
            <div class="interest">
              {{
                numeral(getMonthlyInterest(modal.dayrate)).format(
                  "0,0.000000",
                  Math.ceil
                )
              }}
              <em>USDT</em>
            </div>
          </li>
        </ul>
        <div class="result">
          <p>Receivable summation estimation on the next cycle</p>
          <p class="text-right">
            {{
              numeral(getTradingDayInterest(modal.dayrate)).format(
                "0,0.000000",
                Math.ceil
              )
            }}
            <em>USDT</em>
          </p>
        </div>
      </div>
    </div>
    <div class="content2" v-if="modal.type === 'withdraw'">
      <b-form-group label-for="wallet" label="Your Wallet Address">
        <b-form-input
          id="wallet"
          type="text"
          v-model.number="wallet"
          placeholder=""
          :state="inputState"
        ></b-form-input>
      </b-form-group>
    </div>
    <div class="footer">
      <button
        class="btn-confirm"
        :disabled="isConfirmDisabled"
        @click="handleConfirmClick"
        v-if="modal.type !== 'deposit'"
      >
        Confirm
      </button>
      <button class="btn-cancel" @click="hideAppModal">
        {{ modal.type === "deposit" ? "Close" : "Cancel" }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import numeral from "numeral";
import IconClose from "@/components/icons/IconClose.vue";
import QrcodeVue from "qrcode.vue";

export default {
  name: "AppModal",
  components: {
    IconClose,
    QrcodeVue,
  },
  data() {
    return {
      amount: null,
      wallet: null,
      inputState: null,
      isLoading: false,
      dayrate: null,
    };
  },
  computed: {
    ...mapState(["modal", "token", "userFixedInterestRate"]),
    title() {
      switch (this.modal.type) {
        case "apply":
          return "Apply";
        case "redeem":
          return "Redemption";
        case "deposit":
          return "Deposit";
        case "withdraw":
          return "Withdraw";
        default:
          return "";
      }
    },
    showMaxDescription() {
      return this.modal.max >= 0;
    },
    isConfirmDisabled() {
      if (this.modal.type === "redeem") {
        return this.isLoading;
      }
      if (this.modal.type === "apply") {
        return (
          this.modal.max <= 0 ||
          this.amount <= 0 ||
          this.amount > this.modal.max ||
          this.isLoading
        );
      }
      // if (this.modal.type === "deposit") {
      //   return this.amount <= 0 || this.isLoading;
      // }
      if (this.modal.type === "withdraw") {
        return (
          this.modal.max <= 0 ||
          this.amount <= 0 ||
          this.amount > this.modal.max ||
          this.wallet === undefined ||
          this.wallet.length <= 0 ||
          this.isLoading
        );
      }
      return false;
    },
  },
  watch: {
    "modal.show"() {
      this.amount = null;
      this.wallet = this.modal.wallet;
      this.dayrate = this.modal.dayrate;
    },
  },
  methods: {
    ...mapMutations([
      "hideAppModal",
      "setUserAsset",
      "showAlert",
      "hideAlert",
      "setUserTransactionHistory",
      "setUserFixedInterestRate",
    ]),
    ...mapActions([
      "apply",
      "deposit",
      "withdraw",
      "redeem",
      "getUserAsset",
      "getUserTransactionHistory",
      "getUserFixedInterestRate",
    ]),
    numeral,
    amountFormatter(inputAmount) {
      return numeral(numeral(inputAmount).format("0,0.00", Math.floor)).value();
    },
    assignValue(value) {
      this.amount = Math.floor(Number(value) * 100) / 100;
    },
    getFee() {
      return Math.max(Math.floor(this.amount * 0.005 * 100) / 100, 5);
    },
    getTotal() {
      return this.amount + this.getFee();
    },
    getDailyInterest(dayrate) {
      return this.amount > 0 ? this.amount * dayrate : dayrate;
    },
    getMonthlyInterest(dayrate) {
      let monthrate = Math.pow(1 + dayrate, 30) - 1;
      return this.amount > 0 ? this.amount * monthrate : monthrate;
    },
    getTradingDayInterest(dayrate) {
      const dateNow = new Date(Date.now());
      let bigMonth = [0, 2, 4, 6, 7, 9, 11];
      let smallMonth = [3, 5, 8, 10];
      var totalDay = 1;
      if (bigMonth.includes(dateNow.getMonth()))
        totalDay = 31 - dateNow.getDate() + 7;
      else if (smallMonth.includes(dateNow.getMonth()))
        totalDay = 30 - dateNow.getDate() + 7;
      else totalDay = 28 - dateNow.getDate() + 7;
      let calculatedRate = Math.pow(1 + dayrate, totalDay - 1);
      return this.amount > 0 ? this.amount * calculatedRate : calculatedRate;
    },
    handleSuccess() {
      this.isLoading = false;
      this.hideAppModal();
      this.showAlert({
        variant: "success",
        text: "Operate successfully.",
      });

      this.getUserAsset({
        token: this.token,
        blockChain: "tron",
      }).then((response) => this.setUserAsset(response.data));

      this.getUserFixedInterestRate({
        token: this.token,
      }).then((response) => this.setUserFixedInterestRate(response.data));

      setTimeout(() => {
        this.hideAlert();
      }, 3000);
    },
    async handleConfirmClick() {
      this.isLoading = true;

      try {
        switch (this.modal.type) {
          case "apply":
            await this.apply({
              token: this.token,
              productId: "0",
              amount: this.amount,
            });
            this.handleSuccess();
            this.$router.push("my-page");
            break;
          case "redeem":
            await this.redeem({
              token: this.token,
              productId: "0",
              isRedeem: true,
            });
            this.handleSuccess();
            break;
          // case "deposit":
          //   await this.deposit({
          //     token: this.token,
          //     amount: this.amount,
          //   });
          //   this.handleSuccess();
          //   break;
          case "withdraw":
            await this.withdraw({
              token: this.token,
              amount: this.amount,
              wallet: this.wallet,
            });
            this.handleSuccess();
            break;
          default:
            break;
        }

        const response = await this.getUserTransactionHistory({
          token: this.token,
        });

        this.setUserTransactionHistory(response.data);
      } catch (error) {
        this.isLoading = false;
        this.showAlert({
          text: error?.response?.data?.message,
        });

        setTimeout(() => {
          this.hideAlert();
        }, 3000);
      }
    },
    copyText() {
      const range = document.createRange();
      range.selectNode(this.$refs.walletAddress);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
      selection.removeAllRanges();
      alert("Wallet address was copied!");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .app-modal {
    padding: 24px 32px;
    min-height: 284px;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 12px;

      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.02em;
        color: $black;
      }

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .content {
      margin: 24px 0 8px;

      label {
        margin-bottom: 8px;

        & + div {
          position: relative;

          &::after {
            content: "USDT";
            display: block;
            position: absolute;
            top: 13px;
            right: 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.02em;
            color: $brand-dark;
          }
        }
      }

      input {
        padding: 13px 68px 13px 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: $brand-dark;

        &::placeholder {
          color: $gray-5;
        }

        &.is-invalid {
          background: none;
        }
      }

      p {
        color: $brand-dark;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.02em;
        margin-top: 16px;
      }
    }

    .wallet-info {
      display: flex;
      flex-direction: column;
      p {
        color: $brand-dark;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.02em;
        margin-top: 24px;
      }

      .wallet-table {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        tr {
          width: 100%;
          padding: 8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 200px;
          }
        }

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 94px;
          height: 32px;
          border-radius: 22px;
          border: 1px solid $brand-dark;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.02em;
        }

        .wallet {
          margin: 8px;
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
        }

        .btn-copy {
          margin-top: 16px;
          color: $brand-dark;
          background-color: $white;
        }
      }
    }

    .content2 {
      margin: 8px 0 8px;

      label {
        margin-bottom: 8px;

        & + div {
          position: relative;

          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 13px;
            right: 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.02em;
            color: $brand-dark;
          }
        }
      }

      input {
        padding: 13px 68px 13px 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: $brand-dark;

        &::placeholder {
          color: $gray-5;
        }

        &.is-invalid {
          background: none;
        }
      }
    }

    .form-group {
      & + .form-group {
        margin-top: 8px;
      }
    }

    .description {
      display: inline-block;
      width: 100%;
      text-align: right;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: $gray-2;
      margin-top: 8px;

      em {
        text-decoration: underline;
        color: $primary-dark-1;
        cursor: pointer;
        font-style: normal;
      }
    }

    .interest-trial {
      margin: 20px 0 8px;
      padding: 0;
      list-style: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: #000000;

      ul {
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #dbe3e4;

        > li {
          display: flex;
          justify-content: space-between;
          margin-bottom: 36px;

          .interest {
            > em {
              font-style: normal;
              color: #7a8182;
            }
          }
        }
      }

      .result {
        margin-top: 40px;

        > p {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.02em;
          color: #000000;

          > em {
            font-style: normal;
            color: #7a8182;
          }

          &.text-right {
            text-align: right;
          }
        }
      }
    }

    .footer {
      margin-top: 48px;
      display: flex;
      flex-direction: row-reverse;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 94px;
        height: 32px;
        border-radius: 22px;
        border: 1px solid $brand-dark;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.02em;
      }

      .btn-confirm {
        margin-left: 12px;
        color: $white;
        background-color: $brand-dark;

        &:disabled {
          background-color: $gray-8;
          border-color: $gray-8;
          color: $gray-6;
          cursor: not-allowed;
        }
      }

      .btn-cancel {
        color: $brand-dark;
        background-color: $white;
      }
    }
  }
}
</style>
